<template>
<Page id="administrator_tournaments" title="Turneringar">
      <Loader v-if="tournamentsloading" loadingtext="Hämtar turneringar..."></Loader>
      <div v-else>
            <b-row class="mb-2">
                  <b-col>
                        <b-input v-model="filter" placeholder="Sök..."></b-input>
                  </b-col>
                  <b-col class="text-right">
                        <b-button @click="NewTournament()" variant="primary">Lägg till</b-button>
                  </b-col>
            </b-row>

            <b-table sort-by="id" :sort-desc="true" hover :items="tournaments" :fields="headerfields">

                  <!-- <template v-slot:cell(paid)="data">
                  <div :class="getOrderPaymentStatusClass(data.item)">{{data.value}}</div>
            </template>

            <template v-slot:cell(status)="data">
                  <div :class="getOrderStatusClass(data.item)">{{data.value}}</div>
            </template> -->

                  <template v-slot:cell(handle)="data">
                        <span class="pointer" @click="EditTournament(data)">
                              <inline-svg :src="svg('common/edit-2.svg')"></inline-svg>
                        </span>
                  </template>
            </b-table>
      </div>

      <b-modal size="lg" :hide-footer="true" :visible="showAdd" :title="addTournamentTitle" no-close-on-backdrop no-close-on-esc>
            <AddTournament v-on:saved="Saved()" v-on:cancel="AddClose()" :tournament="currentAddTournament"></AddTournament>
      </b-modal>
</Page>
</template>

<script>
const Page = () => import("@/components/common/Page");
const Alert = () => import("@/components/miscellaneous/Alert");
const Loader = () => import("@/components/miscellaneous/Loader");
const AddTournament = () => import("@/components/administrator/tournaments/AddTournament");

import tournamentsenum from "@/helpers/enums/tournaments";

export default {
      name: "Tournaments",
      components: {
            Page,
            Alert,
            Loader,
            AddTournament
      },
      data() {
            return {
                  filter: "",
                  showAdd: false,
                  currentAddTournament: {},
                  headerfields: [{
                              key: 'id',
                              label: "Id",
                              sortable: true
                        },
                        {
                              key: 'name',
                              label: "Namn",
                              sortable: true
                        },
                        {
                              key: 'startdate',
                              label: 'Start',
                              sortable: true,
                              class: 'text-right'
                        },
                        {
                              key: "sport",
                              label: "Sport",
                              sortable: true,
                              formatter: (value) => {
                                    return tournamentsenum.sport[value];
                              },
                        },
                        {
                              key: "status",
                              label: "Status",
                              sortable: true,
                              class: "text-center",
                              formatter: (value) => {
                                    return tournamentsenum.status[value];
                              },
                        },
                        {
                              key: "active",
                              label: "Aktiv?",
                              sortable: true,
                              class: "text-center",
                              formatter: (value) => {
                                    return tournamentsenum.active[value].text;
                              },
                        },
                        {
                              key: "handle",
                              label: "Hantera",
                              class: "text-center"
                        }
                  ]
            };
      },
      metaInfo: {
            title: "Turneringar",
            meta: [{
                  name: "description",
                  content: "Valbara turneringar",
            }, ],
      },
      computed: {
            tournamentsloading() {
                  return this.$store.getters["administratorget/tournamentsloading"];
            },
            tournaments() {
                  var items = this.$store.getters["administratorget/tournaments"];
                  if (!items)
                        return false;
                  return items.filter((t) => {
                        return t.name.toLowerCase().indexOf(this.filter.toLowerCase()) > -1 ||
                              tournamentsenum.sport[t.sport].toLowerCase().indexOf(this.filter.toLowerCase()) > -1
                  });
            },
            addloading() {
                  return this.$store.getters["administratorpost/tournamentloading"];
            },
            addTournamentTitle() {
                  if (this.currentAddTournament.name != "")
                        return "Editera " + this.currentAddTournament.name;
                  return "Skapa ny turnering";
            }
      },
      methods: {
            NewTournament() {
                  this.currentAddTournament = {
                        id: "0",
                        name: "",
                        description: "",
                        status: 0,
                        active: 0,
                        opendate: this.$date.dateNowWithoutTime(),
                        startdate: this.$date.dateNowWithoutTime(),
                        sport: 1,
                        teamtype: 0,
                        teamage: 0
                  };
                  this.showAdd = true;
            },
            EditTournament(t) {
                  this.currentAddTournament = t.item;
                  this.showAdd = true;
            },                      
            AddClose() {
                  this.showAdd = false;
                  //this.currentAddTournament = false;
            },
            svg(path) {
                  return this.$images.svg(path);
            },
            Saved(){
                  this.getTournaments();
                  this.AddClose();
            },
            getTournaments() {
                  this.$store.dispatch("administratorget/getTournaments");
            }

      },
      mounted() {
            this.getTournaments();
      },
};
</script>
